import request from '@/until/request.js'
const prefixName = '/gm/proPres'

// 获取供应商列表
export function GetPrescribeList(data){
  return request({
    url: prefixName,
    method: 'get',
    params: data
  })
}

// 获取供应商详情
export function GetPrescribeDetail(id){
  return request({
    url: prefixName + "/details",
    method: 'get',
    params: {id}
  })
}
// 获取供应商详情
export function getCode(type){
  return request({
    url: prefixName + "/getPresCode",
    method: 'get',
    params: {type}
  })
}
// 创建供应商
export function AddPrescribe(result){
  return request({
    url: prefixName,
    method: 'post',
    data: result
  })
}
// 修改供应商
export function EditPrescribe(result){
    return request({
        url: prefixName,
        method: 'PUT',
        data: result
    })
}
// 删除供应商
export function DeletePrescribe(id){
    return request({
        url: prefixName + '/' + id,
        method: 'delete'
    })
}
